import ServicesModules from '@/api'
import { useAuth } from '~/stores/authentication'

export const useProfileService = () => {
    const AuthStore = useAuth()

    const {
        public: { API_URL },
    } = useRuntimeConfig()

    return ServicesModules.Profile(API_URL || undefined, AuthStore.token || undefined)
}

export const useAuthService = () => {
    const AuthStore = useAuth()

    const {
        public: { API_URL },
    } = useRuntimeConfig()

    return ServicesModules.Auth(API_URL || undefined, AuthStore.token || undefined)
}

export const useContentService = () => {
    const AuthStore = useAuth()

    const {
        public: { API_URL },
    } = useRuntimeConfig()

    return ServicesModules.Content(API_URL || undefined, AuthStore.token || undefined)
}
export const useGeneralService = () => {
    const AuthStore = useAuth()

    const {
        public: { API_URL },
    } = useRuntimeConfig()

    return ServicesModules.General(API_URL || undefined, AuthStore.token || undefined)
}
