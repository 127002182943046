import queryString from 'query-string'

import { Models } from '~/types/models'
import ENDPOINTS from '../endpoint'

export const Content = (baseURL?: string, token?: string) => ({
    COLLECTIONS: (query: { limit?: number; category_slugs?: string; page?: number }) =>
        $fetch<
            ContentResponses.Collections & {
                totals: {
                    collections: number
                    expositions: number
                }
            }
        >(ENDPOINTS.content.get.collections, {
            method: 'GET',
            query,
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),

    EXHIBITIONS: (query: {
        limit?: number
        category_slugs?: string
        page?: number
        year?: number
        after_year?: number
        before_year?: number
    }) =>
        $fetch<ContentResponses.Exhibitions>(ENDPOINTS.content.get.exhibitions.data, {
            method: 'GET',
            query,
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    EXHIBITIONS_COUNT: () =>
        $fetch<{ past: number; current: number; future: number; all: number }>(
            ENDPOINTS.content.get.exhibitions.counts,
            {
                method: 'GET',
                baseURL,
                ...(token ? { headers: [['Authorization', token]] } : {}),
            }
        ),
    SPECIAL_PROJECTS: (query: { limit?: number; category_slugs?: string; page?: number }) =>
        $fetch<ContentResponses.Collections>(ENDPOINTS.content.get.specialProjects, {
            method: 'GET',
            baseURL,
            query,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    POST_DETAILS: (slug: string) =>
        $fetch<{ data: Models.CollectionResource }>(
            ENDPOINTS.content.get.postDetails.replace('_POST_', slug),
            {
                method: 'GET',
                baseURL,
                ...(token ? { headers: [['Authorization', token]] } : {}),
            }
        ),
    ARTISTS: (query?: { limit?: number; years?: string; post_ids?: number[]; page?: number }) => {
        const params = queryString.stringify(query || {}, { arrayFormat: 'comma' })

        return $fetch<ContentResponses.Artists>(ENDPOINTS.content.get.artists + '?' + params, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        })
    },
    DATES: (query?: { end_at?: number; start_at?: number }) =>
        $fetch<{ data: string[] }>(ENDPOINTS.content.get.dates, {
            method: 'GET',
            query,
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    ARTIST: (slug: string) =>
        $fetch<{
            data: Models.ArtistResource
            authorNavigation: {
                next: { slug: string; name: string; last_name: string }
                previous: { slug: string; name: string; last_name: string }
            }
        }>(ENDPOINTS.content.get.artist.replace('_SLUG_', slug), {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    PAGES: () =>
        $fetch<Models.PageCollection>(ENDPOINTS.content.get.page, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    PAGE: <T>(slug: string) =>
        $fetch<T>(ENDPOINTS.content.get.page.replace('_SLUG_', slug), {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    SEARCH: (query: { query: string; page?: number }) =>
        $fetch<ContentResponses.Search>(ENDPOINTS.content.get.search, {
            method: 'GET',
            query: {
                ...query,
                limit: 999,
            },
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
})

export namespace ContentResponses {
    interface GenericList<T> extends Models.PaginationMetadata {
        data: Array<T>
    }
    export interface Search {
        all: GenericList<Models.CollectionResource | Models.ArtistResource> & {
            totals: { artists: number; collections: number }
        }
        artists: GenericList<Models.ArtistResource>
        collections: GenericList<Models.CollectionResource>
    }
    export interface Collections extends Models.CollectionCollection, Models.PaginationMetadata {}
    export interface Exhibitions extends Models.CollectionCollection, Models.PaginationMetadata {}
    export interface Artists extends Models.ArtistCollection, Models.PaginationMetadata {}
}
