import { Models } from '~/types/models'
import ENDPOINTS from '../endpoint'

export const Auth = (baseURL?: string, token?: string) => ({
    SIGN_IN: (body: { email: string; password: string }) =>
        $fetch<AuthResponses.SignIn>(ENDPOINTS.auth.post.signIn, {
            method: 'POST',
            body,
            baseURL,
        }),
    SIGN_UP: (body: { email: string; password: string; name: string; surname: string; born_date: string }) =>
        $fetch<boolean>(ENDPOINTS.auth.post.signUp, {
            method: 'POST',
            body,
            baseURL,
        }),
    FACEBOOK_CALLBACK: (query: { access_token: string }) =>
        $fetch<AuthResponses.SignIn>(ENDPOINTS.auth.post.socialCallback.facebook, {
            method: 'GET',
            baseURL,
            query,
        }),
    GOOGLE_CALLBACK: (query: { access_token: string }) =>
        $fetch<AuthResponses.SignIn>(ENDPOINTS.auth.post.socialCallback.google, {
            method: 'GET',
            baseURL,
            query,
        }),
    RECOVERY_REQUEST: (query: { email: string }) =>
        $fetch<{}>(ENDPOINTS.auth.get.recovery, {
            method: 'GET',
            baseURL,
            query,
        }),
    RECOVERY_DATA: (body: { email: string; token: string; password: string; confirm_password: string }) =>
        $fetch<boolean>(ENDPOINTS.auth.post.recovery, {
            method: 'POST',
            baseURL,
            body,
        }),
    ACCOUNT_VERIFICATION: (url: string) =>
        $fetch<AuthResponses.SignIn>(url, {
            baseURL: undefined,
            method: 'GET',
        }),
})

export namespace AuthResponses {
    export interface SignIn {
        access_token: string
        user: Models.UserResource
    }
    export interface Profile extends Models.UserResource {}
}
