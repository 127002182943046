import { Models } from '~/types/models'
import ENDPOINTS from '../endpoint'

export const Profile = (baseURL?: string, token?: string) => ({
    PROFILE_ARTISTS: () =>
        $fetch<ProfileResponses.ProfileArtists>(ENDPOINTS.profile.get.artists, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    PROFILE_COLLECTIONS: () =>
        $fetch<ProfileResponses.ProfileCollections>(ENDPOINTS.profile.get.collections, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    PROFILE_EXHIBITS: () =>
        $fetch<ProfileResponses.ProfileCollections>(ENDPOINTS.profile.get.exhibits, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    PROFILE_SPECIAL_PROJECTS: () =>
        $fetch<ProfileResponses.ProfileCollections>(ENDPOINTS.profile.get.specialProjects, {
            method: 'GET',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    CREATE_BOOKMARK: (body: {
        bookmarkable_type: 'artist' | 'collection' | 'exhibit' | 'specialProject'
        bookmarkable_id: number
    }) =>
        $fetch<void>(ENDPOINTS.profile.post.bookmarks, {
            method: 'POST',
            baseURL,
            body,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    DELETE_BOOKMARK: (body: {
        bookmarkable_type: 'artist' | 'collection' | 'exhibit' | 'specialProject'
        bookmarkable_id: number
    }) =>
        $fetch<void>(ENDPOINTS.profile.delete.bookmarks, {
            method: 'DELETE',
            baseURL,
            body,
            ...(token ? { headers: [['Authorization', token]] } : {}),
        }),
    USER_PROFILE: (cookie_token?: string) => {
        const authToken = cookie_token || token

        return $fetch<ProfileResponses.Profile>(ENDPOINTS.profile.get.profile, {
            method: 'GET',
            baseURL,
            ...(authToken ? { headers: [['Authorization', authToken]] } : {}),
        })
    },
    SET_USER_PROFILE: (body: {
        name: string
        surname: string
        born_date: string
        avatar?: File
        email?: string
    }) => {
        const formData = new FormData()

        for (const key in body) {
            if (typeof body[key as keyof typeof body] === 'string') {
                formData.append(key, body[key as keyof typeof body] as string)
            } else {
                formData.append(
                    key,
                    body[key as keyof typeof body] as File,
                    (body[key as keyof typeof body] as File).name
                )
            }
        }

        return $fetch(ENDPOINTS.profile.post.profile, {
            method: 'POST',
            baseURL,
            ...(token ? { headers: [['Authorization', token]] } : {}),
            body: formData,
        })
    },
})

export namespace ProfileResponses {
    export interface Profile extends Models.UserResource {}
    export interface ProfileCollections extends Models.CollectionCollection {}
    export interface ProfileArtists extends Models.ArtistCollection {}
}
