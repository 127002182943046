import { Auth } from '@/api/services/auth'
import { Content } from '@/api/services/content'
import { Profile } from '@/api/services/profile'
import { General } from '@/api/services/general'

export default {
    Auth,
    Content,
    Profile,
    General,
}
