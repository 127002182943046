import { Models } from '~/types/models'
import ENDPOINTS from '../endpoint'

export const General = (baseURL?: string, token?: string) => ({
    CONTACT: (body: { email: string; name: string; last_name: string; message: string; subject: string }) =>
        $fetch<{
            data: {
                message: string
            }
        }>(ENDPOINTS.general.post.contact, {
            method: 'POST',
            body,
            baseURL,
        }),
    NEWSLETTER: (body: { email: string }) =>
        $fetch<{
            error: boolean
            message: string
        }>(ENDPOINTS.general.post.newsletter, {
            method: 'POST',
            body,
            baseURL,
        }),
})
