export default {
    auth: {
        post: {
            signUp: '/auth/signup',
            signIn: '/auth/signin',
            recovery: '/auth/reset-password',
            socialCallback: {
                google: '/auth/google/callback',
                facebook: '/auth/facebook/callback',
            },
        },
        get: {
            recovery: '/auth/reset-password',
        },
        delete: {},
    },
    profile: {
        post: {
            profile: '/profile',
            bookmarks: '/profile/bookmarks',
        },
        get: {
            artists: '/profile/bookmarks/artists',
            collections: '/profile/bookmarks/collections',
            exhibits: '/profile/bookmarks/exhibits',
            specialProjects: '/profile/bookmarks/special-projects',
            profile: '/profile',
        },
        delete: {
            bookmarks: '/profile/bookmarks',
        },
    },
    general: {
        post: {
            contact: '/contact',
            newsletter: '/subscription',
        },
    },
    content: {
        get: {
            collections: '/collections',
            specialProjects: '/special-projects',
            exhibitions: { data: '/exhibits', counts: '/exhibits-count' },

            postDetails: '/posts/_POST_',
            artists: '/artists',
            artist: '/artists/_SLUG_',
            dates: '/post-dates',
            search: '/search',
            pages: '/pages',
            page: '/pages/_SLUG_',
        },
    },
}
