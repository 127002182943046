export const useModal = defineStore('modal', {
    state: () => ({
        modal: ref<ModalType>(null),
    }),
    actions: {
        setModal(modal: ModalType) {
            this.modal = modal
        },
    },
})

type ModalType = null | {
    name: string
    info: object
    onClose?: (target?: 'overlay') => void
}
